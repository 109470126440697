import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)
const adminRoutes =[
  {
    path: "/auth/Dashboard",
    component: ()=>import("@/views/Dashboard.vue"),
    meta: {title:'仪表盘'},
  },{
    path: "/auth/Admin",
    component: ()=>import("@/views/Admin.vue"),
    meta: {title:'管理员'},
  },
  {
    path: "/auth/Record",
    component: ()=>import("@/views/Record.vue"),
    meta: {title:'生产记录'},
  },
  {
    path: "/auth/Management",
    component: ()=>import("@/views/Management.vue"),
    meta: {title:'分类'},
  }, {
    path: "/auth/Area",
    component: ()=>import("@/views/Area.vue"),
    meta: {title:'区域'},
  },{
    path: "/auth/Product",
    component: ()=>import("@/views/Product.vue"),
    meta: {title:'产品管理'},
  },{
    path: "/auth/ChangePass",
    component: ()=>import("@/views/ChangePass.vue"),
    meta: {title:'修改密码'},
  }, {
    path: "/auth/ClientEntity",
    component: ()=>import("@/views/ClientEntity.vue"),
    meta: {title:'客户单位'},
  },{
    path: "/auth/User",
    component: ()=>import("@/views/User.vue"),
    meta: {title:'员工'},
  },
]



const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    component: ()=>import("@/views/Login.vue"),
    meta: {title:'平台登录'},
  },
  {
    path: "/auth",
    component: ()=>import("@/views/Header.vue"),
    redirect: "/auth/dashboard",
    children: adminRoutes
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = 'FOKA | '+to.meta.title
  }
  if (to.path === "/login") {
    return next();
  }
  //判断是否是访问/home下的页面
  if (to.path.indexOf("/auth") === 0) {
    let usersToken = localStorage.getItem("token")
    if (!usersToken) {
      return next("/login");
    }
  }
  return next();
})
export default router
