import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import * as echarts from 'echarts'

// import VueAMap from 'vue-amap'; //引入高德地图
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: 'bdb6a082584329de3e658ba146a7d587',
//   plugin: [ // 这里根据自己项目按需引入插件
//     'AMap.Autocomplete',
//     'AMap.PlaceSearch',
//     'AMap.Scale',
//     'AMap.OverView',
//     'AMap.ToolBar',
//     'AMap.MapType',
//     'AMap.PolyEditor',
//     'AMap.CircleEditor',// 圆形编辑器插件
//     "AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
//     "AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
//     "AMap.CitySearch",
//   ]
// });
// window._AMapSecurityConfig = {
//   securityJsCode:'你的安全密钥',
// }
// // 解决高德地图刷新显示不出来
// const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/))
// amapKeys.forEach(key => { localStorage.removeItem(key) })


Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


