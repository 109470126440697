"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function(config) {
      let whiteList = [];
      console.log("Path :->>>>>>>>>>>>>>>>>>>" +config.url)
      // 对响应数据进行操作
      if (config.url.indexOf("admin/auth") !== -1) {
        config.headers["Authorization"] = localStorage.getItem("AdminToken");
        return config;
      }
      return config;
    },
);
// Add a response interceptor
//配置响应拦截
_axios.interceptors.response.use(
    (res) => {
      if (res.data.code !== 401) {
        return res;
      } else {
        Notification({
          title: '警告',
          message: "登录超时,请重新登录!",
          type: 'error',
        })
        setTimeout(function() {
          location.href = "/"
        }, 1000)
      }
    },
    (error) => {
      Notification({
        title: '警告',
        message: "网络异常！",
        type: 'error',
      })
    }
);


Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
