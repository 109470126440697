<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
  components: {

  }
}
</script>

<style>
.td{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.el-pager li.active{
  color: #6D61EA !important;
}
.el-pager li:hover{
  color: #6D61EA !important;
}
/* 针对所有元素 */

::-webkit-scrollbar{
  width: 0;
}
</style>
